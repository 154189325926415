/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import flow from 'lodash/fp/flow'
import omitBy from 'lodash/fp/omitBy'
import mapValues from 'lodash/fp/mapValues'
import isUndefined from 'lodash/fp/isUndefined'
import isPlainObject from 'lodash/fp/isPlainObject'
import moment from 'moment'

// Libs
import toCaseKeys, { CASES } from '@/utils/to-case-keys'
import toPredicateValues from '@/utils/to-predicate-values'
import handleErrorByToast from '@/methods/handleErrorByToast'
import { handleSentryEvent } from '@/methods/handleSentryEvent'
import getSearchParams from '@/methods/url/getSearchParams'

// Constants
import { envConfigs, ENVIORNMENT } from '@/constants/config'
import { STORAGE_DATA } from '@/constants/storageData'
import { ERROR_CODE } from '@/constants/errorData'
import { isLiveMallClub, SYSTEM_TYPE } from '@/constants/systemType'

export const defaultNormalizer = (response) => response

const whiteList = [
  ERROR_CODE.RECORDING_ERROR,
  ERROR_CODE.OUT_OF_STOCK,
  ERROR_CODE.CLAIM_COUPON_CODE_OTHER_ERROR,
  ERROR_CODE.CLAIM_COUPON_CODE_QUOTA_ZERO,
  ERROR_CODE.CLAIM_COUPON_CODE_EXPIRED,
  ERROR_CODE.CLAIM_COUPON_CODE_CLAIMED,
  ERROR_CODE.LIVE_ROOM_CLOSED,
]

class Service {
  constructor() {
    this.config = {}
    this.name = 'SERVICE_NAME'
  }

  static normalizeList(list, normalizer) {
    return [...(list || [])].map((item) => normalizer(item))
  }

  static normalizePayloadWithPagination({ count, list, page, pagingIndex, pagingSize, requestDate }, normalizer) {
    return {
      count,
      list: this.normalizeList(list, normalizer),
      page,
      pagingIndex,
      pagingSize,
      requestDateTime: requestDate,
    }
  }

  showLogger(action, response) {
    const responseStyle = 'font-weight: bold; color: #B5B5B5;'

    // console.groupCollapsed(`api status: ${action}`)

    // console.log('%c response', responseStyle, response)

    console.groupEnd()
  }

  getOption() {
    return {
      toResponseCase: CASES.CAMEL,
      toRequestCase: CASES.SNAKE,
    }
  }

  getApiConfig() {
    const locale = process.browser ? document.documentElement.lang : ''

    const locationData = typeof window === 'undefined' ? {} : JSON.parse(window.sessionStorage.getItem(STORAGE_DATA.LOCATION_DATA))

    const searchParams = getSearchParams()

    return {
      baseURL: envConfigs.apiUrl,
      headers: {
        'Tutor-Header-Locale': locale,
        'Tutor-Header-CountryCode': searchParams?.country_code || locationData?._dittoCountryCode,
        ...(isLiveMallClub && { 'Tutor-Header-AppSrc': SYSTEM_TYPE.LIVE_MALL_CLUB }),
      },
    }
  }

  getAccessToken() {
    if (typeof window === 'undefined') return {}

    const tokenData = JSON.parse(window.localStorage.getItem(STORAGE_DATA.TOKEN))

    return tokenData?.token
  }

  removeAccessToken() {
    if (typeof window === 'undefined') return

    return window.localStorage.removeItem(STORAGE_DATA.TOKEN)
  }

  getAxiosInstance() {
    const apiConfig = this.withAccessToken
      ? Object.assign(this.getApiConfig(), {
          withCredentials: true,
          headers: {
            Authorization: this.getAccessToken() && `Bearer ${this.getAccessToken()}`,
            ...this.getApiConfig().headers,
            // 'Test-ClientSn': TEST_CLIENT_SN[0], // api 提供測試 clientSn，有需要可以用，但不確定每個 clientSn 對應的資料或身份是什麼
          },
        })
      : this.apiConfig

    const axiosInstance = axios.create(apiConfig)

    axiosInstance.interceptors.request.use(
      (config) => {
        if (typeof window === 'undefined') return config

        const params = config.params || {}

        const search = window.location.search
        const searchParams = new URLSearchParams(search)

        const cache = searchParams.get('cache')

        if (cache) params.cache = cache

        config.params = params

        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    return axiosInstance
  }

  getRequestConfig() {
    const { params, data, ...restConfig } = this.config
    const requestConfig = restConfig

    if (isPlainObject(params)) {
      requestConfig.params = this.handleParameter(params)
    }

    if (isPlainObject(data)) {
      requestConfig.data = this.handleParameter(data)
    }

    if (isPlainObject(requestConfig.headers) && requestConfig.headers['content-type'] === 'multipart/form-data') {
      const data = new FormData()

      Object.entries(requestConfig.data).forEach(([key, value]) => {
        Array.isArray(value) ? value.forEach((value) => data.append(key, value)) : data.append(key, value)
      })
      requestConfig.data = data
    }

    return requestConfig
  }

  debug(reason) {
    const { url } = this.config
    const { message } = reason

    console.warn(`${url} \n - status: ${reason?.status ?? reason.code} \n - message: ${message}\n\n`, reason)
  }

  handleParameter(parameter) {
    const objectComparator = (value) => isPlainObject(value) || Array.isArray(value)
    const denormalizedParameter = this.denormalizer(parameter)

    const casedParameter = toCaseKeys(denormalizedParameter, this.getOption().toRequestCase, { objectComparator })

    const predicator = (object) =>
      Array.isArray(object)
        ? object.filter((value) => !isUndefined(value))
        : flow(
            omitBy(isUndefined),
            mapValues((value) => (typeof value === 'string' ? value.trim() : value)),
          )(object)

    const predicatedParameter = toPredicateValues(casedParameter, predicator, {
      objectComparator,
    })

    return { ...predicatedParameter }
  }

  handleResponse(response) {
    const isApiFail = response instanceof Error

    if (isApiFail) return this.handleFailure(response)

    const casedData = toCaseKeys(response.data, this.getOption().toResponseCase)

    const { apiMeta, data } = casedData

    const isRequestSuccess = apiMeta?.success && apiMeta.code === 0

    if (isRequestSuccess) {
      return this.handleSuccess(data)
    }

    return this.handleError({ ...casedData, status: response.status })
  }

  showSuccessLogger(data) {
    this.showLogger(`${this.name}_SUCCESS`, data)

    return data
  }

  handleSuccess(casedData) {
    const normalizedData = this.normalizer(casedData)

    const predicator = (object) => (Array.isArray(object) ? object.filter((value) => !isUndefined(value)) : flow(omitBy(isUndefined))(object))

    const predicatedData = toPredicateValues(normalizedData, predicator)

    if (process.env.DEPLOY_ENV !== ENVIORNMENT.PROD) this.showSuccessLogger(predicatedData)

    return predicatedData
  }

  // API 200 的 error
  handleError(error) {
    this.showLogger(`${this.name}_FAILURE`, error)

    const { apiMeta, data } = error

    const { code, msg, trace } = apiMeta

    handleSentryEvent({
      message: `[${this.name}-${code}]`,
      level: 'error',
      extra: {
        msg,
        errorData: data,
        traceid: trace?.traceid,
      },
    })

    const errorData = { name: this.name, isResponseError: true, errorCode: code, message: msg, traceId: apiMeta?.trace?.traceid }

    const isShowErrorToast = !whiteList.includes(errorData?.errorCode)

    if (isShowErrorToast) handleErrorByToast({ error: errorData })

    throw { isResponseError: true, errorCode: code, message: msg, errorData: data, traceId: apiMeta?.trace?.traceid }
  }

  // API 200 外的錯誤
  async handleFailure(error) {
    let newError = error

    if (newError?.isResponseError) throw { ...error }

    if (!newError?.isResponseError) newError = error.response

    const casedErrorData = toCaseKeys(newError?.data, this.getOption().toResponseCase) || {}

    this.showLogger(`${this.name}_FAILURE`, casedErrorData)

    const { apiMeta = {}, data } = casedErrorData

    const { code, msg, trace } = apiMeta

    const errorData = {
      name: this.name,
      isResponseError: true,
      errorCode: code,
      message: msg,
      traceId: apiMeta?.trace?.traceid,
      status: newError?.status,
    }

    if (newError?.status === 502) {
      handleSentryEvent({
        message: `[${this.name}-${code}]`,
        level: 'error',
        extra: {
          msg,
          errorData: data,
          traceid: trace?.traceid,
        },
      })

      return null // 如果 API 502 就直接 return null，至少讓 SSR 可以繼續進行，要再看有沒有更好的處理方法
    }

    if (newError?.status === 401) {
      if (typeof window === 'undefined') return

      window.localStorage.setItem(STORAGE_DATA.IS_TIMEOUT, true)
      window.dispatchEvent(new Event('storage'))
    } else {
      const isShowErrorToast = !whiteList.includes(errorData?.errorCode)

      if (isShowErrorToast) handleErrorByToast({ error: errorData })

      handleSentryEvent({
        message: `[${this.name}-${code}]`,
        level: 'error',
        extra: {
          msg,
          errorData: data,
          traceid: trace?.traceid,
        },
      })
    }

    throw { status: newError?.status, errorCode: apiMeta?.code, message: msg, errorData: data, name: this.name, traceId: apiMeta?.trace?.traceid }
  }

  callApi() {
    const axiosInstance = this.getAxiosInstance()
    const requestConfig = this.getRequestConfig()

    this.showLogger(`${this.name}_REQUEST`, moment().format())

    return axiosInstance(requestConfig)
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleFailure(error))
  }
}

Service.prototype.currentEnv = process.env.DEPLOY_ENV
Service.prototype.normalizer = defaultNormalizer
Service.prototype.denormalizer = defaultNormalizer
Service.prototype.withAccessToken = true

export default Service

export function getCallApiFunction(ApiService) {
  return ApiService.callApi()
}
